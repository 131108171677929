<template>
  <v-card>
    <v-card-title>
      Recent Referral
    </v-card-title>
    <v-data-table :headers="headers" :items="getDaskboard" fixed-header height="300">
      <template #[`item.date`]="{ item }">
        <pre>{{ moment.utc(item.date).format('DD-MM-YYYY HH:mm') }}</pre>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Current: 'primary',
      Professional: 'success',
      Rejected: 'error',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      moment,
      headers: [
        { text: 'Notification date', value: 'date' },
        { text: 'Donor Name', value: 'donor_name' },
        { text: 'Referral Name', value: 'referral_name' },
        { text: 'Referral Contact Number', value: 'referral_contact_number' },
        { text: 'Referrer type', value: 'category_type_of_the_requesting_person' },

      ],
      getDaskboard: [],

      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      statusColor,


    }
  },
  props: ['staf_list'],
  watch: {
    staf_list: {
      handler() {
        if ((this.staf_list).length > 0) {
          this.ViewDaskBoard()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if ((this.staf_list).length > 0) {
      this.ViewDaskBoard()
    }
  },
  methods: {
    async ViewDaskBoard() {
      if (this.staf_list) {
        this.getDaskboard = this.staf_list
        // console.log("Hello Data",this.getDaskboard)
        this.totallist = this.staf_list.length

      } else {
        this.getDaskboard = []
        this.totallist = 0
      }
    },
  }
}
</script>
