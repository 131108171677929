<template>
  <v-card class="rounded-b-0">
    <v-card-title class="align-start">
      <span>Referral Division</span>

      <v-spacer></v-spacer>


    </v-card-title>

    <v-card-text>


      <div id="pie-chart"></div>

    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
export default {

  setup() {



    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
  props: ['referral_divisions'],
  watch: {
    referral_divisions: {
      handler() {
        if (this.referral_divisions) {
          this.chartMap()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.referral_divisions) {
      this.chartMap()
    }
  },
  methods: {

    async chartMap() {
      var data12 = this.referral_divisions
      var Bank = data12.Eye_Bank_Staff
      var Hospital = data12.Hospital_Staff
      var Volunteer = data12.Volunteer
      const arr = []
      arr[0] = Bank
      arr[1] = Hospital
      arr[2] = Volunteer

      var options = {
        series: arr,
        chart: {
          width: '100%',
          type: 'pie',
        },
        legend: {
          position: 'bottom',
        },
        dataLabels: {
          style: {
            fontSize: "12px",
          }
        },
        colors: ['#1bbc9b', '#00e396', '#feb019'],
        labels: ['Eye Bank Staff', 'Hospital Staff ', 'Volunteer'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      };

      var pir_chart = new ApexCharts(document.querySelector("#pie-chart"), options);
      pir_chart.render();
    }
  }
}
</script>
