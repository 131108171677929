<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Weekly Notification Received</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>


      <div id="chart"></div>


    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  data() {
    return {
      // weekly_report: []
    }
  },
  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'


    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },

  props: ['weekly_report'],
  watch: {
    weekly_report: {
      handler() {
        if (this.weekly_report.length > 0) {
          this.chartMap()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.weekly_report.length > 0) {
      this.chartMap()
    }
  },
  methods: {

    async chartMap() {
      var data12 = this.weekly_report
      const sectorCall = data12.map((d) => parseFloat(d["sum(c)"]))
      const sectordate = data12.map((d) => d["reg_day_name"])

      var options = {
        chart: {
          height: 480,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        colors: ['#1bbc9b'],
        plotOptions: {
          bar: {
            columnWidth: '40%',
            distributed: true,
            borderRadius: 5,
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        series: [
          {
            name: "Notification",
            type: "column",
            data: sectorCall
          }
        ],
        stroke: {
          width: [0, 4],
          curve: 'smooth'
        },
        labels: sectordate,
        xaxis: {},
        yaxis: []
      };

      var chart = new ApexCharts(document.querySelector("#chart"), options);

      chart.render();
    }
  }
}
</script>
