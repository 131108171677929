<template>
  <v-card>
    <v-card-title class="justify-start">
      <span class="font-weight-semibold text-h5 ">Statistics Card</span>
    </v-card-title>

    <!-- <v-card-subtitle class="mb-8 mt-n5 d-flex justify-center">
      <span class="font-weight-semibold text--primary me-1">Total 48.5% Growth</span>
      <span>😎 this month</span>
    </v-card-subtitle> -->

    <v-card-text>
      <v-row>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiAccountOutline }}

            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Total No. of Donor`s Notification
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.total_donor }}
            </h3>
          </div>
        </v-col>

        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Consented Donors
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.consented_donors }}
            </h3>
          </div>
        </v-col>

        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiTrendingUp }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Not Consented Donors
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.not_consented }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiLabelOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Not Suitable Donor
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.notSuitableDonor }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiDotsVertical }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Total No. of Tissue
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.total_tissue }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiLabelOutline }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Rejected Tissue
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.rejected_tissue }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6" md="4" class="d-flex align-center">
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon color="white" size="35">
              {{ icons.mdiCalendarRange }}

            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Accepted Tissue
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ dashboarddata.accepted_tissue }}
            </h3>
          </div>
        </v-col>


      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiCalendarRange } from '@mdi/js'


export default {
  props: ['dashboarddata'],
  data() {
    return {
      icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiCalendarRange
      },
    }
  },


}
</script>
