<template>
  <v-row>
    <v-col cols="12" md="12" v-if="this.userDesignationVal == 'Admin'">
      <v-card class="p-5">
        <h1 class="pa-5">Welcome <bold>Admin</bold>
        </h1>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <dashboard-statistics-card :dashboarddata="dashboarddata"></dashboard-statistics-card>
    </v-col>
    <v-col cols="12" sm="6" md="12">
      <dashboard-weekly-overview :weekly_report="weekly_report"></dashboard-weekly-overview>
    </v-col>
    <v-col cols="12" md="6" sm="6">
      <dashboard-card-total-earning :referral_divisions="referral_divisions"></dashboard-card-total-earning>
    </v-col>
    <v-col cols="12" md="6" sm="6">
      <DashboardReferral :center_branches="center_branches"></DashboardReferral>
    </v-col>
    <v-col cols="12">
      <dashboard-datatable :staf_list="staf_list"></dashboard-datatable>
    </v-col>
  </v-row>
</template>
<script>

import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'
import DashboardDatatable from './DashboardDatatable.vue'
import DashboardReferral from './DashboardReferral.vue'
import daskboardserice from '@/service/Dashboard.service'
const userDesignation = JSON.parse(localStorage.getItem('token'))
const userDesignationVal = userDesignation.userDesignation

export default {
  data() {
    return {
      dashboarddata: [],
      weekly_report: [],
      referral_divisions: undefined,
      center_branches: [],
      staf_list: [],
      userDesignationVal: ""
    }
  },
  components: {
    StatisticsCardVertical,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardWeeklyOverview,
    DashboardDatatable,
    DashboardReferral,
  },
  watch: {
    options: {
      handler() {
        this.ViewDaskBoard()
      },
      deep: true,
    },
  },
  async mounted() {
    this.ViewDaskBoard()
  },
  methods: {
    async ViewDaskBoard() {
      const service = new daskboardserice();
      let response = await service.getDaskboard();
      this.dashboarddata = response.stats;
      this.weekly_report = response.weekly_report;
      this.referral_divisions = response.referral_divisions;
      this.center_branches = response.centere;
      this.staf_list = response.staf_list;

    },
  },
}
</script>
